* {
    font-family: 'Poppins', sans-serif !important;
}

::-moz-selection {
    /* Code for Firefox */
    background: $primary-color !important;
}
  
::selection {
    background: $primary-color !important;
}
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: $text-color;
    border-radius: 50px;
    transition: $transition;
}

::-webkit-scrollbar:hover {
    width: 8px;
    transition: $transition;
}

::-webkit-scrollbar-track {
    background: transparent;
}

body {
    background-color: $white;
    margin: 0;
    padding: 0;
}

input {
    outline: 0 !important;
    background-color: transparent;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
}

button {
    outline: 0 !important;
}

a {
    // outline: none !important;
    // color: $primary-color !important;
    // text-decoration: none !important;
    cursor: pointer !important;
}
  