.bot-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: inline-block;
}

#mypp_web_chat {
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
  text-align: right;
  z-index: 99999999999;

  .bot-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    display: inline-block;
  }

  .open-chat-btn {
    width: 60px;
    height: 60px;
    background-color: $primary-color;
    border: 0;
    border-radius: 30px;
    box-shadow: $box-shadow;
    color: $white-color;

    .number {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $red-color;
      font-size: 12px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }

  .web-chat-container {
    width: 100%;
    max-width: 400px;
    height: 75vh;
    margin-bottom: 1em;
    box-shadow: $box-shadow;
    border-radius: 8px;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.09);
    transition: all 0.75s ease-in;
    transform: translateY(0);
    text-align: left;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 70px auto 60px 25px;
    z-index: 10000000;

    .file-download {
      // background-color: $white-color;
      padding: 0.3em 0.5em;
      // border-radius: 5px;
      font-size: 12px;
      font-weight: 300;
      // display: flex;
      // position: relative;
    }

    .img-attachment {
      width: 300px;
      max-width: 100%;
      height: 200px;
      object-fit: cover;
      margin-bottom: 0.3em;
      display: block;
      border-radius: 15px;
      cursor: pointer;
    }

    &.slide-up {
      transform: -50px;
      transition: all 0.75s ease-in;
    }

    .header-part {
      background: rgb(34, 34, 34);
      background: linear-gradient(
        156deg,
        rgba(0, 0, 0, 1) 0%,
        rgb(65, 65, 65) 100%
      );
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em;
      border-radius: 8px 8px 0 0;
      height: 70px;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        color: rgba(255, 255, 255, 1);
        padding: 0;
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .chat-btn {
      background-color: $white-color;
      padding: 0.5em 1em;
      border: 1px solid $primary-color;
      color: $primary-color;
      margin-bottom: 0.5em;
      margin-right: 0.5em;
      border-radius: 30px;
      font-size: 12px;
      transition: $transition;

      &:hover {
        border-color: darken($primary-color, 20%);
        transition: $transition;
        color: darken($primary-color, 20%);
      }
    }

    .chat-messages-container {
      background-color: $light-grey;
      padding: 1.5em 0.5em;
      overflow-y: auto;

      // &::-webkit-scrollbar {
      //     display: none;
      // }

      .chat-message-container {
        width: 100%;
        margin-bottom: 0.5em;
        font-size: 14px;

        .chat-message {
          background-color: $white-color;
          border-radius: 20px;
          border: 1px solid rgba(0, 0, 0, 0.09);
          border-radius: 20px;
          padding: 0.5em 1em;
          display: inline-block;
          max-width: 80%;
        }

        &.chose-option {
          display: flex;
          flex-direction: row;
          justify-self: flex-end;

          .options-message {
            margin-left: auto;
            max-width: 100%;
            text-align: right;

            button {
              background-color: $white-color;
              padding: 0.5em;
              border: 1px solid $primary-color;
              color: $primary-color;
              margin-bottom: 0.5em;
              margin-right: 0.5em;
              border-radius: 30px;
              font-size: 12px;
              transition: $transition;

              &:hover {
                border-color: darken($primary-color, 20%);
                transition: $transition;
                color: darken($primary-color, 20%);
              }
            }
          }
        }

        &.outgoing-message {
          margin-left: auto;
          max-width: 80%;
          text-align: right;

          .chat-message {
            background-color: $primary-color;
            border: 0;
            color: $white-color;
          }
        }

        &.other-category {
          width: 100%;

          .chat-message {
            max-width: 100% !important;
            padding-top: 1em;
            padding-bottom: 1em;

            select {
              width: 100%;
              padding: 0.5em;
              margin-bottom: 0.5em;
              border: 1px solid rgba(0, 0, 0, 0.09);
              border-radius: 8px;
              color: $text-color;
              font-size: 14px;
              outline: none;
            }

            input {
              width: 100%;
              padding: 0.5em;
              margin-bottom: 1em;
              border-radius: 8px;
              border: 1px solid rgba(0, 0, 0, 0.09);
              outline: none;
            }

            .main-btn {
              width: 100%;
              padding: 0.5em;
              font-size: 14px;
              text-transform: initial;
            }
          }
        }
      }
    }

    .write-message-container {
      .type-message {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0.5em;

        textarea {
          width: 100%;
          padding: 5px 20px !important;
          min-height: 40px !important;
          max-height: 80px;
          resize: none;
          border: 0;
          outline: none;
          font-size: 14px;
          background-color: $light-grey;
          border-radius: 50px;
        }

        .type-message-btns {
          display: flex;
          flex-direction: row;

          button {
            background-color: transparent;
            border: 0;
            color: $text-color;
            margin-right: 0.5em;

            &:last-child {
              margin-right: 0;
            }

            &.send-btn {
              background-color: $primary-color;
              color: $white-color;
              border-radius: 8px;
              padding: 0.5em;
            }
          }
        }
      }
    }
  }
}

.bot-message-alert {
  background-color: $white-color;
  width: 300px;
  position: absolute;
  bottom: 0em;
  right: 4.5em;
  border-radius: 15px;
  animation: slideInFromRight 1s ease-out 1s forwards;
  opacity: 0;

  .message {
    position: relative;
    padding: 1em;
  }

  p {
    margin: 0;
    font-size: 14px;
    text-align: left;
  }

  .close-btn {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    font-size: 12px !important;
    padding: 0;
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 30px;
    background-color: #999;
    color: $white-color;
    transition: $transition;

    &:hover {
      transition: $transition;
      background-color: #ababab;
    }
  }
}

@keyframes slideInFromRight {
  0% {
    // transform: translateX(20%);
    opacity: 0;
  }

  100% {
    // transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  #mypp_web_chat {
    bottom: 1em;
    right: 1em;

    .open-chat-btn {
      width: 50px;
      height: 50px;
    }

    .web-chat-container {
      height: 85vh;
      margin-bottom: -1em;
      margin-right: -1em !important;
      box-shadow: $box-shadow;
      width: 100vw;
    }
  }

  .bot-message-alert {
    right: 4em;
    width: 250px;
  }
}
